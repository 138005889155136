import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import ArrowsWhitePath from "../images/arrows-white.svg";
import BackgroundImagePath from "../images/automotive/nxrt-automotive4.jpg";
import BackgroundImage1Path from "../images/automotive/nxrt-automotive2.jpg";
import BackgroundImage2Path from "../images/automotive/nxrt-automotive7.jpg";
import BackgroundImage3Path from "../images/automotive/nxrt-automotive6.jpg";
import { Routes } from "../components/layout/header";
import DarkBackgroundImagePath from "../images/nxrt-background-blue.svg";
import LightBackgroundImagePath from "../images/nxrt-background.svg";
import ArrowsBlackPath from "../images/arrows-black.svg";
import CarImagePath from "../images/automotive/nxrt-automotive.jpg";
import CarSubImage1Path from "../images/automotive/nxrt-automotive4.jpg";
import CarSubImage2Path from "../images/automotive/nxrt-automotive5.jpg";
import CarSubImageVideoPath from "../images/automotive/nxrt-automotive11.jpg";
import CarSubVideoPath from "../images/videos/automotive.mp4";
import RealtimeEditorImage1Path from "../images/automotive/nxrt-automotive3.jpg";
import RealtimeEditorImage2Path from "../images/automotive/nxrt-automotive10.jpg";
import RealtimeEditorImage3Path from "../images/automotive/nxrt-automotive8.jpg";
import RealtimeEditorImage4Path from "../images/automotive/nxrt-automotive9.jpg";

//#region Styled Components

const StyledBackgroundContainer = styled.div`
  background: url('${DarkBackgroundImagePath}');
  background-size: cover;
`;

const StyledBackgroundContainer2 = styled.div`
  background: url('${LightBackgroundImagePath}');
  background-size: cover;
  background-position-x: -1500px;
`;

//#region Hero
const StyledHeroSection = styled.section`
  min-height: 900px;
  padding: 2rem 5rem 2rem 5rem;
  background: url('${BackgroundImagePath}');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StyledHeroHeaderContainer = styled.div`
  max-width: 1344px;
  display: flex;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroHeader = styled.h1`
  text-transform: uppercase;
  line-height: 1.2;

  > span {
    display: inline-block;
  }

  > span:nth-of-type(1) {
    margin-top: 25rem;
    margin-left: 4rem;
    font-weight: 300;
    font-size: 8rem;
    text-transform: uppercase;
    letter-spacing: 14px;
  }

  > span:nth-of-type(2) {
    margin-left: 40rem;
    font-weight: 300;
    font-size: 6rem;
    opacity: 0.69;
    letter-spacing: 9px;
  }

  @media (max-width: 78rem) {
    > span:nth-of-type(1) {
      margin-top: 15rem;
      margin-left: 4rem;
      font-size: 4rem;
    }

    > span:nth-of-type(2) {
      margin-left: 30rem;
      font-size: 3rem;
    }
  }

  @media (max-width: 53rem) {
    > span:nth-of-type(1) {
      margin-top: 10rem;
    }

    > span:nth-of-type(2) {
      margin-left: 0;
      display: inline-block;
      width: 100%;
      text-align: right;
    }
  }

  @media (max-width: 44rem) {
    > span:nth-of-type(1) {
      margin-left: 2rem;
      font-size: 3rem;
    }

    > span:nth-of-type(2) {
      font-size: 2rem;
    }
  }
`;

const StyledHeroDescriptionContainer = styled.div`
  max-width: 1354px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  pointer-events: none;
`;

const StyledHeroDescription = styled.div`
  max-width: 25rem;
  padding: 20px;
  border: 1px solid white;
  font-size: 1.3rem;
  line-height: 1.5;
`;

const StyledImageBackgrounds = styled.div`
  max-width: 1354px;
  width: calc(100% - 10rem);
  position: absolute;
  display: flex;
  flex-wrap: wrap;

  > img {
    margin: 1.25%;
    align-self: flex-start;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;

    :hover {
      transform: translateY(-8px);
    }
  }

  > img:nth-child(1) {
    width: 40%;
    margin-left: -10rem;
    margin-top: 150px;
  }

  > img:nth-child(2) {
    align-self: flex-end;
    width: 40%;
  }

  > img:nth-child(3) {
    width: 55%;
    margin-left: 20%;
  }
`;

const StyledHeroArrowsContainer = styled.div`
  margin-top: -4rem;
  max-width: 1354px;
  width: 100%;
  z-index: 1;
  height: 1px;
  margin-bottom: 10rem;
`;

const StyledHeroArrows = styled.img`
  margin-left: 5rem;
  height: 10rem;

  @media (max-width: 53rem) {
    margin-top: 5rem;
  }
`;
//#endregion

//#region Simulator Section
const StyledSimulatorSection = styled.section`
  min-height: 100vh;
  padding-top: 15rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

const StyledSimulatorContainer = styled.div`
  margin-bottom: 5rem;
  width: 100%;
  max-width: 53rem;
  z-index: 1;

  p {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;

    :first-of-type {
      font-weight: bold;
    }
  }

  @media (max-width: 75rem) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

const StyledSimulatorHeadline = styled.div`
  margin-left: -5rem;
  margin-right: -5rem;
  margin-bottom: 3rem;
  position: relative;

  > h3 {
    margin: 0;
    font-weight: normal;
    font-size: 5rem;
    letter-spacing: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }

  > span {
    position: absolute;
    font-size: 15rem;
    font-weight: 100;
    line-height: 1;
    left: 50%;
    bottom: -5rem;
    transform: translateX(-50%);
    color: lightgray;
  }

  @media (max-width: 75rem) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledArrows = styled.img`
  height: 10rem;
`;
//#endregion

//#region Car Section
const StyledCarSection = styled.section`
  padding: 0 15rem 5rem 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 93rem) {
    padding: 0 5rem 5rem 5rem;
  }
`;

const StyledCar = styled.div`
  width: 100%;
  max-width: 89rem;
  position: relative;

  > img {
    width: 100%;
  }
`;

const StyledCarText = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;

  > div:first-child {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
  }

  > div:last-child {
    padding: 2rem;
    display: flex;
    flex-direction: column;

    > span:first-child {
      color: lightgray;
      font-size: 3rem;
      letter-spacing: 0.5rem;
    }

    > span:last-child {
      margin-left: -3rem;
    }
  }

  @media (max-width: 93rem) {
    bottom: unset;
    top: 0;
  }

  @media (max-width: 58rem) {
    top: 100%;
    color: black;

    > div:last-child {
      > span:first-child {
        color: darkgrey;
      }
    }
  }

  @media (max-width: 53rem) {
    top: 100%;
    color: black;

    > div:last-child {
      display: none;
    }
  }
`;

const StyledCarPositions = styled.div`
  color: lightgray;
  font-size: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  > span:nth-child(1) {
    position: absolute;
    top: 38%;
    left: 40%;
  }

  > span:nth-child(2) {
    position: absolute;
    top: 67%;
    left: 42%;
  }

  > span:nth-child(3) {
    position: absolute;
    top: 74%;
    left: 63%;
  }
`;

const StyledCarSubImages = styled.div`
  margin: 2rem -1rem 10rem -1rem;
  height: 22rem;
  width: 100%;
  max-width: 89rem;
  display: flex;
  justify-content: flex-start;
  opacity: 0.85;
  overflow: hidden;

  @media (max-width: 58rem) {
    display: none;
  }

  > img,
  > video {
    flex-shrink: 0;
    height: 100%;
    width: auto;
  }

  > video,
  img:nth-child(2) {
    margin: 0 2rem;
  }
`;

const StyledCarSectionArrows = styled.img`
  height: 10rem;

  @media (max-width: 58rem) {
    margin-top: 15rem;
  }
`;
//#endregion

//#region Content
const StyledContentSection = styled.section`
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

const StyledContentContainer = styled.div`
  padding: 0 5rem;
  min-height: 100vh;
  margin-top: 10rem;
  margin-bottom: 5rem;
  width: 100%;
  max-width: 53rem;
  z-index: 1;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;

    :nth-of-type(2) {
      font-weight: bold;
    }
  }

  img {
    margin-top: 10rem;
  }
`;

const StyledContentHeadline = styled.div`
  margin-bottom: 3rem;
  position: relative;

  > h3 {
    margin: 0;
    font-weight: normal;
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
  }

  > span {
    position: absolute;
    font-size: 15rem;
    font-weight: 100;
    line-height: 1;
    left: 50%;
    bottom: -5rem;
    transform: translateX(-50%);
    color: lightgray;
  }
`;
//#endregion

//#region RealtimeEditorSuite
const StyledRealtimeEditorSuiteSection = styled.section`
  padding: 0 25rem;
  padding-bottom: 10rem;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    margin-bottom: 1rem;
    font-size: 5rem;
    letter-spacing: 0.5rem;
    font-weight: 300;
  }

  > p {
    margin-bottom: 10rem;
    max-width: 59rem;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
  }

  @media (max-width: 107rem) {
    padding: 0 10rem;
  }
`;

const StyledRealtimeEditorSuiteContent = styled.div`
  max-width: 69rem;
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto;

  img {
    width: 100%;
    height: 100%;
    align-self: stretch;
    object-fit: cover;
  }

  > :nth-child(1) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }

  > :nth-child(2) {
    grid-row: 3 / 11;
    grid-column: 1 / 2;
  }

  > :nth-child(3) {
    grid-row: 1 / 4;
    grid-column: 2 / 4;
  }

  > :nth-child(5) {
    grid-row: 7 / 11;
    grid-column: 2 / 4;
  }

  > :nth-child(6) {
    display: none;
  }

  @media (max-width: 107rem) {
    grid-template-rows: auto auto auto auto auto auto;

    > :nth-child(2) {
      grid-row: 3 / 7;
    }

    > :nth-child(4) {
      display: none;
    }

    > :nth-child(5) {
      grid-row: 4 / 7;
    }
  }
`;

const StyledRealtimeEditorSuiteTextbox = styled.div`
  padding: 2rem;
  border: 1px solid black;

  > h3 {
    margin-top: 0;
    font-size: 2rem;
    font-weight: 300;
  }

  > p {
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: 300;
  }
`;
//#endregion

const IndexPage: React.FC = () => {
  return (
    <Layout currentRoute={Routes.IndustryAutomotive}>
      <StyledBackgroundContainer>
        <StyledHeroSection>
          <StyledHeroHeaderContainer>
            <StyledHeroHeader>
              <span>Automotive</span>
              <br />
              <span>XQ.Avatar</span>
            </StyledHeroHeader>
          </StyledHeroHeaderContainer>
          <StyledHeroDescriptionContainer>
            <StyledHeroDescription>
              <p>
                <strong>XQ.Avatar</strong> is an extraordinary sales and
                marketing tool delivering a highly interactive customer journey.
              </p>
            </StyledHeroDescription>
          </StyledHeroDescriptionContainer>
          <StyledHeroArrowsContainer>
            <StyledHeroArrows
              src={ArrowsWhitePath}
              onClick={() =>
                document
                  .getElementById("content")
                  .scrollIntoView({ behavior: "smooth" })
              }
            />
          </StyledHeroArrowsContainer>
          <StyledImageBackgrounds>
            <img src={BackgroundImage1Path} />
            <img src={BackgroundImage2Path} />
            <img src={BackgroundImage3Path} />
          </StyledImageBackgrounds>
        </StyledHeroSection>
      </StyledBackgroundContainer>
      <StyledBackgroundContainer2>
        <StyledSimulatorSection id="content">
          <StyledSimulatorContainer>
            <StyledSimulatorHeadline>
              <span>A</span>
              <h3>Car as a simu&shy;lator</h3>
            </StyledSimulatorHeadline>
            <p>
              XQ.Avatar is our first product to be fully integrated and
              compliant with the NX3 sensor system.
            </p>
            <p>
              The car is the focal point of the experience and serves as the
              central control unit within the virtual environment.
            </p>
            <p>
              Push the gas pedal and interact with your driver-assistance
              systems with multiple control modes such as CAN-Bus, ODB2 or
              sensor technology - guaranteeing a reliable and fast connection,
              customisable for each vehicle type and individual use case.
            </p>
          </StyledSimulatorContainer>
          <StyledArrows src={ArrowsBlackPath} />
        </StyledSimulatorSection>
        <StyledCarSection>
          <StyledCar>
            <img src={CarImagePath} />
            <StyledCarText>
              <div>
                <span>A NX3 SENSORS</span>
                <span>B MISHBILD</span>
                <span>C SIM UX</span>
              </div>
              <div>
                <span>XQ.Avatar</span>
                <span>REAL VEHICLE</span>
              </div>
            </StyledCarText>
            <StyledCarPositions>
              <span>A</span>
              <span>B</span>
              <span>C</span>
            </StyledCarPositions>
          </StyledCar>
          <StyledCarSubImages>
            <img src={CarSubImage1Path} />
            <img src={CarSubImageVideoPath} />
            <img src={CarSubImage2Path} />
          </StyledCarSubImages>
          <StyledCarSectionArrows src={ArrowsBlackPath} />
        </StyledCarSection>

        <StyledContentSection>
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>B</span>
              <h3>MISHBILD</h3>
            </StyledContentHeadline>
            <p>Virtually real; realistic virtuality</p>
            <p>
              XQ.Avatar integrates the material reality within the virtual world
              using MISHBILD, our patented algorithm for transitive mixed
              reality.
            </p>
            <p>
              Sitting in a physical car is a familiar experience which we
              augment, based on the most intuitive and straightforward
              interaction metaphor there is: car windows. Inside the vehicle,
              there is only the material world - but outside the windows, you
              see a virtual city, a highway, the mountains or any other
              environment you can imagine.
            </p>
            <StyledArrows src={ArrowsBlackPath} />
          </StyledContentContainer>
          <StyledContentContainer>
            <StyledContentHeadline>
              <span>C</span>
              <h3>
                Usability
                <br />
                Excellence
              </h3>
            </StyledContentHeadline>
            <p></p>
            <p>
              XQ.Avatar has been developed and designed as a holistic experience
              following the "XR First" guidelines.
            </p>
            <p>
              Each building is optimised for virtual reality, with each
              assistant's interaction and demonstration designed to create the
              best possible customer journey, improve customer understanding,
              increase satisfaction and thereby create more incentives to invest
              into modern safety and comfort solutions.
            </p>
            <StyledArrows src={ArrowsBlackPath} />
          </StyledContentContainer>
        </StyledContentSection>

        <StyledRealtimeEditorSuiteSection>
          <h2>RT Editor Suite</h2>
          <p>
            With the RT Editor Suite, we can bring any real existing assistant
            into the virtual world and thus create a "digital twin". Trial the
            EBA in a risky, realistic but fictious situation or demonstrate the
            snow-aware ACC on a hot summer day.
          </p>
          <StyledRealtimeEditorSuiteContent>
            <StyledRealtimeEditorSuiteTextbox>
              <h3>BUILDINGS</h3>
              <p>
                The environment, landscape and each building in the virtual
                world has been created using procedural generation and specific
                domain-knowledge of VR content. Inspired by the Central European
                architecture, we can create a familiar yet new environment,
                optimised for the demonstration of driver-assistance systems.
              </p>
            </StyledRealtimeEditorSuiteTextbox>
            <img src={RealtimeEditorImage1Path} />
            <img src={RealtimeEditorImage2Path} />
            <img src={RealtimeEditorImage3Path} />
            <StyledRealtimeEditorSuiteTextbox>
              <h3>STREETS</h3>
              <p>
                The RT Editor Suite creates roads and intersections within
                minutes. These roads comply with traffic rules and regional road
                traffic regulations, ensuring a road network in compliance with
                the law - one that can be used and understood by people,
                autonomous cars and driver-assistance systems.
              </p>
            </StyledRealtimeEditorSuiteTextbox>
            <img src={RealtimeEditorImage4Path} />
          </StyledRealtimeEditorSuiteContent>
        </StyledRealtimeEditorSuiteSection>
      </StyledBackgroundContainer2>
    </Layout>
  );
};

export default IndexPage;
